.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map {
  width: 720px;
  height: 600px;
}

#header-title {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 900;
  font-size: 48px;
  line-height: normal;
  color: #000000;
}

.footer-title h3 {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 900;
  font-size: 48px;
  line-height: normal;
}

.orange-text {
  color: #F24902;
}

#cards-wrapper h1 {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 900;
  font-size: 48px;
  line-height: normal;
}


.no-metamask {
  background-color: #FFF6F3;
}

.no-metamask h3 {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 900;
  font-size: 32px;
  line-height: normal;
}

.no-metamask a {
  margin-top: 16px !important;
}


#header-desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #515151;
}

.btn-style {
  background: linear-gradient(90deg, #FC4C02 0%, #CB3E03 100%);
  border-radius: 10px;
  color: white;

  line-height: normal;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: uppercase;

  padding-top: 12px;
  padding-bottom: 12px;

  border: 4px solid #FBDACD;
  border-radius: 10px;
}

.btn:disabled:hover {
  text-decoration: none;
  background: linear-gradient(90deg, #FC4C02 0%, #CB3E03 100%);
}

.btn:hover {
  text-decoration: none;
  color: white;
  background: linear-gradient(90deg, #EE5819 0%, #EE5819 100%);
}

.btn-explore, .btn-explore:hover {
  background: #FFFFFF;
  border: 2px solid #FC4C02;
  border-radius: 10px;
  color:  #FC4C02;
}

.cycle-card {
  height: 370px;
}

.cycle-card-header {
  color: #9B9B9B;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.005em;
  text-transform: uppercase;




  /* font-family: Nunito Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 52px; */
}

.cycle-card-header h3 {
  /* font-weight: bold; */
  color: #000000;
  /* font-size: 29px; */

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 52px;
}

.card-body-key {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #9B9B9B;
}

.card-body-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.cycle-card-body .col-12{
  background-image: url("/Vector.png");
  background-position: bottom;
  background-clip: content-box;
  background-size: 850px;
}

.cycle-card-button button {
  width: 100%;
}

footer {
  background-color: #F2F2F3;
}

footer p {
  margin: 0;
}

footer .highlight {
  color: #79808A;
}
